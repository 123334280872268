/////////////////// pagination orders
$(document).ready(function () {
  if (location.pathname === '/orders') {

    let self = this,
      pos_elem,
      scroll_w,
      check_sc,
      pos_stop,
      $l_orders,
      last_tranz,
      block;

    $(window).on('scroll', function () {
      // скроллинг заявок
      $l_orders = $('.list_orders');
      if ($l_orders.css('display') === 'block') {
        last_tranz = $('.list_orders .order:last');

        scroll_w = (Number($(this).height()) + Number($(this).scrollTop()));
        pos_elem = Number(last_tranz.offset().top) + Number(last_tranz.height());
        pos_stop = Number(pos_elem + 20);
        check_sc = scroll_w > pos_elem && scroll_w < pos_stop;


        if ($('div').is('.list_orders') && check_sc) {


          let num = Number($l_orders.attr('data-page-num'));
          let total = Number($l_orders.attr('data-page-total'));

          //console.log(num + " " + total);

          if (num < total) {
            $l_orders.attr('data-page-num', (num + 1));

            $.ajax({
              type: 'post',
              url: '../orders',
              data: {page: (num + 1)},
              dataType: 'html',
              success: function (html) {


                $l_orders.append($(html).find('.list_orders .order'));


              }


            });

          }
        }

      }


    });


  }


});
